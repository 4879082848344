import { ResponseError } from '@thrivesoft/gocore-web';

export class CoreResponseModel {
    constructor(
        public message: string,
        public statusCode: number
    ) { }

    static fromProto(proto: ResponseError): CoreResponseModel {
        return new CoreResponseModel(
            proto.Message,
            proto.StatusCode
        );
    }
}
