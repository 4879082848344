/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TubPatient } from '../models/tub-patient';
import { TubHasActiveChat } from '../models/tub-has-active-chat';
import { TubPatientGraph } from '../models/tub-patient-graph';
import { TubTherapistDetails } from '../models/tub-therapist-details';
import { TubPatientRecord } from '../models/tub-patient-record';
import { TubFhirRiskAssessmentsResponse } from '../models/tub-fhir-risk-assessments-response';
import { TubFhirRiskAssessments } from '../models/tub-fhir-risk-assessments';
import { TubFhirRiskAssessmentsImmutable } from '../models/tub-fhir-risk-assessments-immutable';
@Injectable({
  providedIn: 'root',
})
class PatientsService extends __BaseService {
  static readonly PatientsGetPatientPath = '/patients/{patientId}';
  static readonly PatientsGetPatientChatDataPath = '/patients/{patientId}/chat';
  static readonly PatientsGetPatientGraphDataPath = '/patients/{patientId}/graph';
  static readonly PatientsGetEligibleTherapistsPath = '/patients/{patientId}/eligible-therapists';
  static readonly PatientsSharePatientDataWithTherapistPath = '/patients/{patientId}/share-data/{therapistId}';
  static readonly PatientsGetPatientRecordPath = '/patients/{patientId}/patient-record';
  static readonly PatientsUpdatePatientRecordPath = '/patients/{patientId}/patient-record';
  static readonly PatientsGetRiskAssessmentsPath = '/patients/{patientId}/patient-record/risk-assessments';
  static readonly PatientsAddRiskAssessmentsPath = '/patients/{patientId}/patient-record/risk-assessments';
  static readonly PatientsGetRiskAssessmentPath = '/patients/{patientId}/patient-record/risk-assessments/{riskAssessmentId}';
  static readonly PatientsUpdateRiskAssessmentsPath = '/patients/{patientId}/patient-record/risk-assessments/{riskAssessmentId}';
  static readonly PatientsDeleteRiskAssessmentsPath = '/patients/{patientId}/patient-record/risk-assessments/{riskAssessmentId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * get a patients basic details
   *
   * Retrieve details about a given patient.  Must have access to the patient in order to make the request.
   * @param patientId the patientId of the patient for which to retrieve information
   * @return Ok
   */
  PatientsGetPatientResponse(patientId: string): __Observable<__StrictHttpResponse<TubPatient>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(patientId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPatient>;
      })
    );
  }
  /**
   * get a patients basic details
   *
   * Retrieve details about a given patient.  Must have access to the patient in order to make the request.
   * @param patientId the patientId of the patient for which to retrieve information
   * @return Ok
   */
  PatientsGetPatient(patientId: string): __Observable<TubPatient> {
    return this.PatientsGetPatientResponse(patientId).pipe(
      __map(_r => _r.body as TubPatient)
    );
  }

  /**
   * get a patients basic details
   *
   * Retrieve details about a given patient.  Must have access to the patient in order to make the request.
   * @param patientId the patientId of the patient for which to retrieve information
   * @return Ok
   */
  PatientsGetPatientChatDataResponse(patientId: string): __Observable<__StrictHttpResponse<TubHasActiveChat>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(patientId))}/chat`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubHasActiveChat>;
      })
    );
  }
  /**
   * get a patients basic details
   *
   * Retrieve details about a given patient.  Must have access to the patient in order to make the request.
   * @param patientId the patientId of the patient for which to retrieve information
   * @return Ok
   */
  PatientsGetPatientChatData(patientId: string): __Observable<TubHasActiveChat> {
    return this.PatientsGetPatientChatDataResponse(patientId).pipe(
      __map(_r => _r.body as TubHasActiveChat)
    );
  }

  /**
   * get graph data for a specific user
   *
   * Get the GAD and PHQ scores, with a timestamp of `startFrom` or earlier and 'endAt' or later, for the specified user.
   * @param params The `PatientsService.PatientsGetPatientGraphDataParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `patientId`: the id for the patient whose data we are requesting
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  PatientsGetPatientGraphDataResponse(params: PatientsService.PatientsGetPatientGraphDataParams): __Observable<__StrictHttpResponse<TubPatientGraph>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startFrom != null) __params = __params.set('startFrom', params.startFrom.toString());

    if (params.endAt != null) __params = __params.set('endAt', params.endAt.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/graph`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPatientGraph>;
      })
    );
  }
  /**
   * get graph data for a specific user
   *
   * Get the GAD and PHQ scores, with a timestamp of `startFrom` or earlier and 'endAt' or later, for the specified user.
   * @param params The `PatientsService.PatientsGetPatientGraphDataParams` containing the following parameters:
   *
   * - `startFrom`: The timestamp at which to start retrieving moments from the patient record.
   *
   * - `patientId`: the id for the patient whose data we are requesting
   *
   * - `endAt`: Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
   *
   * @return Ok
   */
  PatientsGetPatientGraphData(params: PatientsService.PatientsGetPatientGraphDataParams): __Observable<TubPatientGraph> {
    return this.PatientsGetPatientGraphDataResponse(params).pipe(
      __map(_r => _r.body as TubPatientGraph)
    );
  }

  /**
   * discover therapists who can interact with a given patient
   *
   * Retrieve a list of all therapists who would be eligible to interact with this patient, if they were invited.
   * In order to discover other eligible therapists, the authenticated therapist must have data access to this patient.
   * This is useful for providing lists of therapists during invite or handover operations.
   * @param params The `PatientsService.PatientsGetEligibleTherapistsParams` containing the following parameters:
   *
   * - `patientId`: ID of the patient for whom to discover eligible therapists
   *
   * - `purpose`: optional purposes flags to filter the eligibility check, defaults to blank (no filter)
   *
   * @return Ok
   */
  PatientsGetEligibleTherapistsResponse(params: PatientsService.PatientsGetEligibleTherapistsParams): __Observable<__StrictHttpResponse<Array<TubTherapistDetails>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    (params.purpose || []).forEach(val => {if (val != null) __params = __params.append('purpose', val.toString())});
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/eligible-therapists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TubTherapistDetails>>;
      })
    );
  }
  /**
   * discover therapists who can interact with a given patient
   *
   * Retrieve a list of all therapists who would be eligible to interact with this patient, if they were invited.
   * In order to discover other eligible therapists, the authenticated therapist must have data access to this patient.
   * This is useful for providing lists of therapists during invite or handover operations.
   * @param params The `PatientsService.PatientsGetEligibleTherapistsParams` containing the following parameters:
   *
   * - `patientId`: ID of the patient for whom to discover eligible therapists
   *
   * - `purpose`: optional purposes flags to filter the eligibility check, defaults to blank (no filter)
   *
   * @return Ok
   */
  PatientsGetEligibleTherapists(params: PatientsService.PatientsGetEligibleTherapistsParams): __Observable<Array<TubTherapistDetails>> {
    return this.PatientsGetEligibleTherapistsResponse(params).pipe(
      __map(_r => _r.body as Array<TubTherapistDetails>)
    );
  }

  /**
   * share a patients data with another therapist
   *
   * As an authenticated therapist, share the specified patients' data with another specified therapist.
   * In order to share patient data with another therapist user, the user must already have access to the patient's data,
   * and the target therapist must be eligible (as determined by organisation membership).
   * @param params The `PatientsService.PatientsSharePatientDataWithTherapistParams` containing the following parameters:
   *
   * - `therapistId`: identifies the therapist with whom the data will be shared
   *
   * - `patientId`: identifies the patient for whom to share data
   */
  PatientsSharePatientDataWithTherapistResponse(params: PatientsService.PatientsSharePatientDataWithTherapistParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/share-data/${encodeURIComponent(String(params.therapistId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * share a patients data with another therapist
   *
   * As an authenticated therapist, share the specified patients' data with another specified therapist.
   * In order to share patient data with another therapist user, the user must already have access to the patient's data,
   * and the target therapist must be eligible (as determined by organisation membership).
   * @param params The `PatientsService.PatientsSharePatientDataWithTherapistParams` containing the following parameters:
   *
   * - `therapistId`: identifies the therapist with whom the data will be shared
   *
   * - `patientId`: identifies the patient for whom to share data
   */
  PatientsSharePatientDataWithTherapist(params: PatientsService.PatientsSharePatientDataWithTherapistParams): __Observable<null> {
    return this.PatientsSharePatientDataWithTherapistResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get patient record details
   *
   * Retrieves a patient's record for a specified patient ID.
   * This method is secured and requires the caller to be authenticated and authorized to access the patient's record.
   * It also performs an audit log of the retrieval operation.
   * @param patientId Patient ID.
   * @return Ok
   */
  PatientsGetPatientRecordResponse(patientId: string): __Observable<__StrictHttpResponse<TubPatientRecord>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(patientId))}/patient-record`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubPatientRecord>;
      })
    );
  }
  /**
   * Get patient record details
   *
   * Retrieves a patient's record for a specified patient ID.
   * This method is secured and requires the caller to be authenticated and authorized to access the patient's record.
   * It also performs an audit log of the retrieval operation.
   * @param patientId Patient ID.
   * @return Ok
   */
  PatientsGetPatientRecord(patientId: string): __Observable<TubPatientRecord> {
    return this.PatientsGetPatientRecordResponse(patientId).pipe(
      __map(_r => _r.body as TubPatientRecord)
    );
  }

  /**
   * Update patient record details
   *
   * Updates a specific patient's record with the provided information.
   * This method handles PATCH requests to modify an existing patient record. It requires that the caller is authenticated
   * and authorized to update the patient's record. It also checks for the validity of the provided ETag to manage concurrent updates.
   * @param params The `PatientsService.PatientsUpdatePatientRecordParams` containing the following parameters:
   *
   * - `patientRecord`: Updated record object.
   *
   * - `patientId`: Patient's ID.
   *
   * - `ETag`: ETag value for concurrency control.
   */
  PatientsUpdatePatientRecordResponse(params: PatientsService.PatientsUpdatePatientRecordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.patientRecord;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/patient-record`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update patient record details
   *
   * Updates a specific patient's record with the provided information.
   * This method handles PATCH requests to modify an existing patient record. It requires that the caller is authenticated
   * and authorized to update the patient's record. It also checks for the validity of the provided ETag to manage concurrent updates.
   * @param params The `PatientsService.PatientsUpdatePatientRecordParams` containing the following parameters:
   *
   * - `patientRecord`: Updated record object.
   *
   * - `patientId`: Patient's ID.
   *
   * - `ETag`: ETag value for concurrency control.
   */
  PatientsUpdatePatientRecord(params: PatientsService.PatientsUpdatePatientRecordParams): __Observable<null> {
    return this.PatientsUpdatePatientRecordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get latest ten risk assessments from a patient record.
   *
   * Retrieves the latest 10 risk assessments for a specified patient ID.
   * This method requires the caller to be authenticated and authorized to access the patient's risk assessments.
   * It also performs an audit log of the retrieval operation.
   * @param patientId Patient's ID.
   * @return Ok
   */
  PatientsGetRiskAssessmentsResponse(patientId: string): __Observable<__StrictHttpResponse<TubFhirRiskAssessmentsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(patientId))}/patient-record/risk-assessments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubFhirRiskAssessmentsResponse>;
      })
    );
  }
  /**
   * Get latest ten risk assessments from a patient record.
   *
   * Retrieves the latest 10 risk assessments for a specified patient ID.
   * This method requires the caller to be authenticated and authorized to access the patient's risk assessments.
   * It also performs an audit log of the retrieval operation.
   * @param patientId Patient's ID.
   * @return Ok
   */
  PatientsGetRiskAssessments(patientId: string): __Observable<TubFhirRiskAssessmentsResponse> {
    return this.PatientsGetRiskAssessmentsResponse(patientId).pipe(
      __map(_r => _r.body as TubFhirRiskAssessmentsResponse)
    );
  }

  /**
   * Add a risk assessment to a patient record.
   *
   * Submits new risk assessments for a patient's record.
   * It requires authentication and authorization to ensure that the caller has the right to update the patient's record.
   * The operation is logged for auditing purposes. It checks for the validity of the patient ID and handles concurrency with ETag verification.
   * @param params The `PatientsService.PatientsAddRiskAssessmentsParams` containing the following parameters:
   *
   * - `riskAssessments`: Risk assessments to add.
   *
   * - `patientId`: Patient's ID.
   */
  PatientsAddRiskAssessmentsResponse(params: PatientsService.PatientsAddRiskAssessmentsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.riskAssessments;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/patient-record/risk-assessments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Add a risk assessment to a patient record.
   *
   * Submits new risk assessments for a patient's record.
   * It requires authentication and authorization to ensure that the caller has the right to update the patient's record.
   * The operation is logged for auditing purposes. It checks for the validity of the patient ID and handles concurrency with ETag verification.
   * @param params The `PatientsService.PatientsAddRiskAssessmentsParams` containing the following parameters:
   *
   * - `riskAssessments`: Risk assessments to add.
   *
   * - `patientId`: Patient's ID.
   */
  PatientsAddRiskAssessments(params: PatientsService.PatientsAddRiskAssessmentsParams): __Observable<null> {
    return this.PatientsAddRiskAssessmentsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get a specific risk assessment from a patient record.
   *
   * Retrieves a specific risk assessment for a given patient ID and risk assessment ID from the Tub backend system.
   * This method ensures that the caller is authenticated and authorized to access the requested patient's risk assessment.
   * It aims to fetch a detailed view of a single risk assessment entry, identified by its unique risk assessment ID.
   * @param params The `PatientsService.PatientsGetRiskAssessmentParams` containing the following parameters:
   *
   * - `riskAssessmentId`: ID of the risk assessment to fetch.
   *
   * - `patientId`: ID of the patient.
   *
   * @return Ok
   */
  PatientsGetRiskAssessmentResponse(params: PatientsService.PatientsGetRiskAssessmentParams): __Observable<__StrictHttpResponse<TubFhirRiskAssessmentsImmutable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/patient-record/risk-assessments/${encodeURIComponent(String(params.riskAssessmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TubFhirRiskAssessmentsImmutable>;
      })
    );
  }
  /**
   * Get a specific risk assessment from a patient record.
   *
   * Retrieves a specific risk assessment for a given patient ID and risk assessment ID from the Tub backend system.
   * This method ensures that the caller is authenticated and authorized to access the requested patient's risk assessment.
   * It aims to fetch a detailed view of a single risk assessment entry, identified by its unique risk assessment ID.
   * @param params The `PatientsService.PatientsGetRiskAssessmentParams` containing the following parameters:
   *
   * - `riskAssessmentId`: ID of the risk assessment to fetch.
   *
   * - `patientId`: ID of the patient.
   *
   * @return Ok
   */
  PatientsGetRiskAssessment(params: PatientsService.PatientsGetRiskAssessmentParams): __Observable<TubFhirRiskAssessmentsImmutable> {
    return this.PatientsGetRiskAssessmentResponse(params).pipe(
      __map(_r => _r.body as TubFhirRiskAssessmentsImmutable)
    );
  }

  /**
   * Update a specific risk assessment in a patient record.
   *
   * Updates a specific risk assessment within a patient's record.
   * This PATCH method allows for partial updates to a risk assessment identified by `riskAssessmentId` for a patient specified by `patientId`.
   * It requires the caller to be authenticated and authorized, checks for valid ETag to manage concurrent updates, and logs the operation for auditing.
   * @param params The `PatientsService.PatientsUpdateRiskAssessmentsParams` containing the following parameters:
   *
   * - `riskAssessmentId`: Risk assessment's ID to update.
   *
   * - `riskAssessment`: Updated risk assessment data.
   *
   * - `patientId`: Patient's ID.
   *
   * - `ETag`: ETag for concurrency.
   */
  PatientsUpdateRiskAssessmentsResponse(params: PatientsService.PatientsUpdateRiskAssessmentsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.riskAssessment;

    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/patient-record/risk-assessments/${encodeURIComponent(String(params.riskAssessmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Update a specific risk assessment in a patient record.
   *
   * Updates a specific risk assessment within a patient's record.
   * This PATCH method allows for partial updates to a risk assessment identified by `riskAssessmentId` for a patient specified by `patientId`.
   * It requires the caller to be authenticated and authorized, checks for valid ETag to manage concurrent updates, and logs the operation for auditing.
   * @param params The `PatientsService.PatientsUpdateRiskAssessmentsParams` containing the following parameters:
   *
   * - `riskAssessmentId`: Risk assessment's ID to update.
   *
   * - `riskAssessment`: Updated risk assessment data.
   *
   * - `patientId`: Patient's ID.
   *
   * - `ETag`: ETag for concurrency.
   */
  PatientsUpdateRiskAssessments(params: PatientsService.PatientsUpdateRiskAssessmentsParams): __Observable<null> {
    return this.PatientsUpdateRiskAssessmentsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete a specific risk assessment from a patient record.
   *
   * Deletes a specific risk assessment from a patient's record.
   * Handles DELETE requests, ensuring the caller is authenticated and authorized. It verifies the ETag for concurrency control and logs the action.
   * Responds with appropriate errors if the patient or risk assessment ID does not exist, if the action is unauthorized, or if there's a conflict due to an expired ETag.
   * @param params The `PatientsService.PatientsDeleteRiskAssessmentsParams` containing the following parameters:
   *
   * - `riskAssessmentId`: Risk assessment's ID to delete.
   *
   * - `patientId`: Patient's ID.
   *
   * - `ETag`: ETag for concurrency.
   */
  PatientsDeleteRiskAssessmentsResponse(params: PatientsService.PatientsDeleteRiskAssessmentsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.ETag != null) __headers = __headers.set('ETag', params.ETag.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/patients/${encodeURIComponent(String(params.patientId))}/patient-record/risk-assessments/${encodeURIComponent(String(params.riskAssessmentId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a specific risk assessment from a patient record.
   *
   * Deletes a specific risk assessment from a patient's record.
   * Handles DELETE requests, ensuring the caller is authenticated and authorized. It verifies the ETag for concurrency control and logs the action.
   * Responds with appropriate errors if the patient or risk assessment ID does not exist, if the action is unauthorized, or if there's a conflict due to an expired ETag.
   * @param params The `PatientsService.PatientsDeleteRiskAssessmentsParams` containing the following parameters:
   *
   * - `riskAssessmentId`: Risk assessment's ID to delete.
   *
   * - `patientId`: Patient's ID.
   *
   * - `ETag`: ETag for concurrency.
   */
  PatientsDeleteRiskAssessments(params: PatientsService.PatientsDeleteRiskAssessmentsParams): __Observable<null> {
    return this.PatientsDeleteRiskAssessmentsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module PatientsService {

  /**
   * Parameters for PatientsGetPatientGraphData
   */
  export interface PatientsGetPatientGraphDataParams {

    /**
     * The timestamp at which to start retrieving moments from the patient record.
     */
    startFrom: number;

    /**
     * the id for the patient whose data we are requesting
     */
    patientId: string;

    /**
     * Limit the query to a specific time period. Note that `endAt` must be a time earlier than `startFrom`.
     */
    endAt?: number;
  }

  /**
   * Parameters for PatientsGetEligibleTherapists
   */
  export interface PatientsGetEligibleTherapistsParams {

    /**
     * ID of the patient for whom to discover eligible therapists
     */
    patientId: string;

    /**
     * optional purposes flags to filter the eligibility check, defaults to blank (no filter)
     */
    purpose?: Array<'lead' | 'chat' | 'access'>;
  }

  /**
   * Parameters for PatientsSharePatientDataWithTherapist
   */
  export interface PatientsSharePatientDataWithTherapistParams {

    /**
     * identifies the therapist with whom the data will be shared
     */
    therapistId: string;

    /**
     * identifies the patient for whom to share data
     */
    patientId: string;
  }

  /**
   * Parameters for PatientsUpdatePatientRecord
   */
  export interface PatientsUpdatePatientRecordParams {

    /**
     * Updated record object.
     */
    patientRecord: TubPatientRecord;

    /**
     * Patient's ID.
     */
    patientId: string;

    /**
     * ETag value for concurrency control.
     */
    ETag: string;
  }

  /**
   * Parameters for PatientsAddRiskAssessments
   */
  export interface PatientsAddRiskAssessmentsParams {

    /**
     * Risk assessments to add.
     */
    riskAssessments: TubFhirRiskAssessments;

    /**
     * Patient's ID.
     */
    patientId: string;
  }

  /**
   * Parameters for PatientsGetRiskAssessment
   */
  export interface PatientsGetRiskAssessmentParams {

    /**
     * ID of the risk assessment to fetch.
     */
    riskAssessmentId: string;

    /**
     * ID of the patient.
     */
    patientId: string;
  }

  /**
   * Parameters for PatientsUpdateRiskAssessments
   */
  export interface PatientsUpdateRiskAssessmentsParams {

    /**
     * Risk assessment's ID to update.
     */
    riskAssessmentId: string;

    /**
     * Updated risk assessment data.
     */
    riskAssessment: TubFhirRiskAssessments;

    /**
     * Patient's ID.
     */
    patientId: string;

    /**
     * ETag for concurrency.
     */
    ETag: string;
  }

  /**
   * Parameters for PatientsDeleteRiskAssessments
   */
  export interface PatientsDeleteRiskAssessmentsParams {

    /**
     * Risk assessment's ID to delete.
     */
    riskAssessmentId: string;

    /**
     * Patient's ID.
     */
    patientId: string;

    /**
     * ETag for concurrency.
     */
    ETag: string;
  }
}

export { PatientsService }
