import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { PropertyCache } from '@thrivesoft/gocore-web';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoChatCacheService {

  constructor(private angularFireAuth: AngularFireAuth) { }

  /**
   * Calculates the cache key by concatenating the cache prefix and the logged-in user's ID.
   * This is the structure of the key that is defined in GoCore.
   * @returns The chat cache key in the format of "'chat-cache-' + [logged-in user ID]".
   */
  private async calculateCacheKey(): Promise<string> {
    const CACHE_KEY_PREFIX = 'chat-cache-';

    const loggedInUserId: string = (await firstValueFrom(this.angularFireAuth.user)).uid;
    return CACHE_KEY_PREFIX + loggedInUserId;
  }

  /**
   * Gets the cached chat messages for the logged-in user and encodes them.
   * @returns A Base64 encoded PropertyCache.
   */
  public async getEncodedChatCache(): Promise<string> {
    const chatCacheKey: string = await this.calculateCacheKey();

    const chatCacheData: string = localStorage.getItem(chatCacheKey);

    if (!chatCacheData) {
      return;
    }

    const propertyCache: PropertyCache = new PropertyCache({
      cache: { [chatCacheKey]: chatCacheData },
    });

      // Encode the PropertyCache object as a Base64 string
      return btoa(JSON.stringify(propertyCache));
  }

  /**
   * Writes a PropertyCache to localstorage.
   */
  public writeToChatCache(propertyCache: PropertyCache): void {

    const chatCacheKey: string = Object.keys(propertyCache.cache)[0];

    localStorage.setItem(chatCacheKey, propertyCache.cache[chatCacheKey]);
  }

  /**
   * Deletes the chat cache for the currently logged-in user.
   */
  public async deleteChatCache(): Promise<void> {
    const chatCacheKey: string = await this.calculateCacheKey();

    localStorage.removeItem(chatCacheKey);
  }
}
