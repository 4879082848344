import { PubNubMsgData } from '@thrivesoft/gocore-web';
import { ChatReplyModel } from './chat-reply-model';

/**
 * TODO: The Meta object is currently used to dump state and should be removed once GoCore eliminates this bad habit
 */
export type MessageMeta = {
  fields?: {
    Temp?: {
      kind?: {
        value: boolean
      }
    }
    [key: string]: any;
  };
  uuid?: string;
  readOnly?: boolean;
  owner?: string;
  reply?: ChatReplyModel;
  sent?: boolean;
};

export class ChatMessageModel {
  constructor(
    public timeId: bigint,
    public text: string,
    public originalId: bigint,
    public uuid: string,
    public edited: boolean,
    public deleted: boolean,
    public liked: { [key: string]: string },
    // TODO: We should really remove the meta object, and pull out any data we need from it into a base property of ChatMessageModel
    public meta: MessageMeta,
    public isPending: boolean,
  ) {}

  static fromProto(proto: PubNubMsgData) {
    return new ChatMessageModel(
      proto.TimeID,
      proto.Text[0], // Only first array entry is the actual message text :(
      proto.OriginalId,
      proto.Uuid,
      proto.Edited,
      proto.Deleted,
      proto.Liked,
      proto.Meta,
      // Pull this bit of important data from the depths of the meta object to make it
      // more easily accessible!
      proto.Meta?.fields?.Temp?.kind.value as boolean
    );
  }

  static messages(messages: PubNubMsgData[]): ChatMessageModel[] {
    // Optional chaining needed because sometimes messages are null
    return messages?.map(c => ChatMessageModel.fromProto(c));
  }

  public getDisplayTime() {
    const correctedTime = Number(this.timeId / BigInt(10000));
    return new Date(correctedTime);
  }
}
